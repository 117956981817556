const DELIMITER = "-";

export function generateId(generator) {
  if (!generator) {
    throw new Error("You need to supply a generator function!");
  }
  const now = new Date().getTime();
  const id = generator();
  return `${now}${DELIMITER}${id}`;
}

export function getTimestampFromId(id) {
  if (!id) {
    throw new Error("No id was supplied!");
  }
  const index = id.indexOf(DELIMITER);
  return parseInt(id.substr(0, index), 10);
}
