/* eslint-disable no-param-reassign */
import cloneDeep from "lodash/cloneDeep";
import uniq from "lodash/uniq";
import {
  VENUE_LOCATION_EAT_IN,
  VENUE_LOCATION_TO_GO
} from "@orda/shared-constants/venue-types";
import moment from "moment-timezone";

const UNDEFINED_TAG = "y_no_sort_tag";

export const calculateSumForVenue = ({
  items,
  orderLocation,
  orderBenefits,
  baseVat,
  sumBeforeEverything,
  sumBenefit,
  sumBenefitSum,
  tipSum
}) => {
  let sumBenefitResult = 0;
  // Check if sumBenefit is applied
  // Also get vat reduction if sumBenefit is paid by venue
  let reductionPercent = 1;
  if (sumBenefit) {
    // Check if sumBenefit is paid by venue
    if (!sumBenefit.paidByORDA) {
      sumBenefitResult = parseFloat(sumBenefitSum);
      reductionPercent = Math.max(
        1 - parseFloat(sumBenefitSum) / parseFloat(sumBeforeEverything),
        0
      );
    } else {
      // No action necessary if sumBenefit is paid by ORDA, sumTotal will not be reduced
    }
  }

  // Get sum for all items
  const results = items.reduce(
    ({ sum, map, itemBenefitsORDA, itemBenefitsVenue }, item) => {
      const vatProperty =
        orderLocation === VENUE_LOCATION_EAT_IN ? "vat" : "vatToGo";
      const orderLocationDefault =
        !orderLocation || orderLocation === VENUE_LOCATION_EAT_IN ? 0.19 : 0.07;
      let vat = item[vatProperty] || baseVat || orderLocationDefault;
      let usableVat = parseFloat(vat);

      // BUG! Workaround for 70 % vat
      if (usableVat === 0.7) {
        usableVat = 0.07;
        vat = 0.07;
      }

      if (!map[vat]) {
        map[vat] = 0;
      }

      // Check if benefit is applied to this item
      let itemPrice = 0;
      let itemBenefitORDA = 0;
      let itemBenefitVenue = 0;
      if (item.benefit && orderBenefits) {
        // Check if benefit is paid by ORDA
        const benefit = orderBenefits[item.benefit];
        if (benefit.paidByORDA) {
          // Apply price before benefit
          itemPrice = parseFloat(item.priceBeforeBenefit || 0);

          itemBenefitORDA =
            parseFloat(item.priceBeforeBenefit || 0) -
            parseFloat(item.priceRaw || 0);

          map[vat] +=
            (parseFloat(item.priceBeforeBenefit || 0) -
              parseFloat(item.priceBeforeBenefit || 0) / (1.0 + usableVat)) *
            reductionPercent;
        } else {
          // Apply price after benefits
          itemPrice = parseFloat(item.priceRaw || 0);

          itemBenefitVenue =
            parseFloat(item.priceBeforeBenefit || 0) -
            parseFloat(item.priceRaw || 0);

          map[vat] +=
            (parseFloat(item.priceRaw || 0) -
              parseFloat(item.priceRaw || 0) / (1.0 + usableVat)) *
            reductionPercent;
        }
      } else {
        // Apply price after benefits
        itemPrice = parseFloat(item.priceRaw || 0);

        map[vat] +=
          (parseFloat(item.priceRaw || 0) -
            parseFloat(item.priceRaw || 0) / (1.0 + usableVat)) *
          reductionPercent;
      }

      return {
        sum: sum + itemPrice,
        map,
        itemBenefitsORDA: itemBenefitsORDA + itemBenefitORDA,
        itemBenefitsVenue: itemBenefitsVenue + itemBenefitVenue
      };
    },
    {
      map: {},
      itemBenefitsORDA: 0,
      itemBenefitsVenue: 0,
      sum: 0
    }
  );
  // Round prices
  let venueSum = Math.round(results.sum);
  let benefitsByORDA = Math.round(results.itemBenefitsORDA);
  let benefitsByVenue = Math.round(results.itemBenefitsVenue);

  if (sumBenefit) {
    // Check if sumBenefit is paid by venue
    if (!sumBenefit.paidByORDA) {
      benefitsByVenue += parseFloat(sumBenefitSum);
    } else {
      benefitsByORDA += parseFloat(sumBenefitSum);
    }
  }

  // Subtract sumBenefit
  venueSum -= sumBenefitResult;

  // Reset venueSum to 0 if benefits have more value than the original value
  venueSum = Math.max(venueSum, 0);

  // Add tip to sumTotal
  venueSum += tipSum || 0;

  return {
    venueSum,
    benefitsByORDA,
    benefitsByVenue,
    vatMap: Object.keys(results.map).reduce(
      (innerMap, vat) => {
        const vatSum = results.map[vat];
        const vatValue = Math.round(vatSum);
        const property = `VAT (${(vat * 100).toFixed().replace(",", ".")}%)`;
        innerMap[property] = vatValue;
        return innerMap;
      },
      {
        "VAT (19%)": 0,
        "VAT (7%)": 0
      }
    )
  };
};

// flattens an array by recurvisley calling itself whenever it encounters
// an array
const expandItems = (list, previousPadding = -1) =>
  list.reduce((acc, item) => {
    if (Array.isArray(item)) {
      return acc.concat(
        expandItems(item, !previousPadding ? 2 : previousPadding + 1)
      );
    }

    if (previousPadding === -1 || item.parent === null) {
      item.padding = 0;
    } else if (previousPadding === 0) {
      item.padding = 2;
    } else {
      item.padding = previousPadding + 1;
    }

    // remove parent, children and index keys
    delete item.parent;
    delete item.index;
    delete item.children;
    delete item.visited;

    return acc.concat([item]);
  }, []);

// function return a dict: {
//   children: [...],
//   overrideTag: ...
// }
const processChildren = (item, parent, itemTree, sortedItems) => {
  let finalChildren = [];
  let overrideTag = null;

  // mark item as visited - this is to prevent revisiting an item
  // from the outer loop
  item.visited = true;

  // process children
  if (item.children) {
    item.children.forEach(childIdx => {
      const { children, overrideTag: tag } = processChildren(
        itemTree[childIdx],
        item,
        itemTree,
        sortedItems
      );

      if (!overrideTag) {
        overrideTag = tag;
      }

      if (children) {
        finalChildren = finalChildren.concat(children);
      }
    });
  }

  const finalSortTag = overrideTag !== null ? overrideTag : item.sortTag;

  if (
    (parent && !finalSortTag) ||
    (parent && parent.sortTag && finalSortTag === parent.sortTag)
  ) {
    // this case will be hit if an item has a parent but doesn't have a sortTag of its own
    // or if it has a parent and its sort tag is same as that of its parent

    // return the item and its children which will be children for the parent
    return {
      children: [item, finalChildren],
      overrideTag: overrideTag ? [overrideTag] : null
    };
  } else if (finalSortTag && finalSortTag.length > 1) {
    // this case handles the override currently it doesn't match previous tags since
    // that functionality is not needed
    // this is an override - currently assuming it's only the last tag
    overrideTag = finalSortTag[finalSortTag.length - 1];

    // this item is not being moved so the item and its children are returned
    // which in turn will be children of the parent
    return {
      children: [item, finalChildren],
      overrideTag: overrideTag ? [overrideTag] : null
    };
  } else if (!finalSortTag) {
    // this case handles the case when an item doesn't have any sort tag i.e. it's undefined

    // the item will be moved to the UNDEFINED_TAG key in the sortedItems dict
    if (!sortedItems[UNDEFINED_TAG]) {
      sortedItems[UNDEFINED_TAG] = [item, finalChildren];
    } else {
      sortedItems[UNDEFINED_TAG].push(item, finalChildren);
    }
    return {
      children: [],
      overrideTag: null
    };
  }

  // this is the case when an item has a single sortTag thus it needs to be
  // moved to the matching key in the sortedItems dict
  if (!sortedItems[finalSortTag[0]]) {
    sortedItems[finalSortTag[0]] = [item, finalChildren];
  } else {
    sortedItems[finalSortTag[0]].push(item, finalChildren);
  }
  return {
    children: [],
    overrideTag: null
  };
};

export const transformOrderItems = items => {
  // clone items so the original items are not affected
  const clonedItems = cloneDeep(items);

  // this dict stores all the item groups with along with parent, children and index
  // properties which is used by the subsequent processChildren call on the individual
  // item groups.
  const reducedItems = {};

  let itemCount = -1; // correct the index
  const stack = [];
  clonedItems.forEach(item => {
    // pop item from the stack if we have gone up the tree
    while (
      stack.length !== 0 &&
      typeof item.padding !== "undefined" &&
      item.padding <= (stack[stack.length - 1].padding || 0)
    ) {
      stack.pop();
    }

    // this will increment the itemCount which is used as a key to store reduced item groups
    // in case an item has notOnKitchenReceipt property the itemCount will be skipped if
    // the stack is empty otherwise it'll add subsequent items to the same group.
    if (stack.length === 0) {
      itemCount++;
    }

    // if the item is not on the receipt ignore and continue
    if (item.notOnKitchenReceipt) {
      return;
    }

    // add parent information as needed
    if (stack.length === 0 || !item.padding || item.padding === 0) {
      item.parent = null;
    } else {
      item.parent = stack[stack.length - 1].index;
    }

    // add index information
    if (reducedItems[itemCount] === undefined) {
      item.index = 0;
    } else {
      item.index = reducedItems[itemCount].length;
    }

    // add children information
    if (item.parent !== null) {
      if (!reducedItems[itemCount][item.parent].children) {
        reducedItems[itemCount][item.parent].children = [item.index];
      } else {
        reducedItems[itemCount][item.parent].children.push(item.index);
      }
    }

    // push item into reducedItems dict
    if (reducedItems[itemCount] === undefined) {
      reducedItems[itemCount] = [item];
    } else {
      reducedItems[itemCount].push(item);
    }

    // push item on the stack
    stack.push(item);
  });

  // this dict contains the items grouped together by sortTags with
  // each sortTag being a separate key
  const sortedItems = {};

  Object.values(reducedItems).forEach(itemTree => {
    // process items in an item group and split based on sortedItems
    itemTree.forEach(item => {
      if (!item.visited) {
        processChildren(item, null, itemTree, sortedItems);
      }
    });
  });

  // reorderedItems will expand arrays for each sortTag while flattening
  // them and correcting the padding values
  let reorderedItems = [];
  Object.keys(sortedItems)
    .sort()
    .forEach(sortTag => {
      reorderedItems = reorderedItems.concat(expandItems(sortedItems[sortTag]));
    });

  return reorderedItems;
};

export function calculateOrderReadyTime({ executedAt, orderTime }, timeZone) {
  const orderMoment = moment.unix(executedAt).tz(timeZone);
  const hour = `${orderTime[0]}${orderTime[1]}`;
  const minute = `${orderTime[2]}${orderTime[3]}`;
  orderMoment.set({
    hour,
    minute,
    second: 0,
    millisecond: 0
  });
  return orderMoment;
}

export function getOrderPickupLocations(orderLocation, pickupFallback, items) {
  const pickups = uniq(
    items.map(item => {
      const pickup = item.pickupName ? item.pickupName : pickupFallback; // Fallback

      return orderLocation === VENUE_LOCATION_TO_GO && item.pickupToGO
        ? item.pickupToGo
        : pickup;
    })
  ).filter(pickup => pickup);

  return pickups.length <= 1
    ? {
        de: pickups[0] || "Essensausgabe",
        en: pickups[0] || "Serving counter"
      }
    : {
        de: pickups.map(pickup => `\n• ${pickup}`),
        en: pickups.map(pickup => `\n• ${pickup}`)
      };
}
